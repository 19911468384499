import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";
import services from "./services";
import paths from "../../utils/paths";

export const useLogin = () => {
  const toast = useToast();
  return useMutation(services.login, {
    onError: (data: AxiosError) => {
      const errObj: ErrorObj = data.response!.data as ErrorObj;
      toast({
        title: "Invalid Credential",
        description: errObj.errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    },

    onSuccess: (data) => {
      console.log(data.data.jwt);
      localStorage.setItem('authToken', data.data.jwt);
    },
  });
};

export const useDeleteAccount = () => {
  const toast = useToast();

  const navigate = useNavigate();

  return useMutation(services.deleteAccount, {
    onError: (data: AxiosError) => {
      const errObj: ErrorObj = data.response!.data as ErrorObj;
      toast({
        title: "Failed",
        description: errObj.errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    },

    onSuccess: () => {
      navigate(paths.deleteSuccess);
    },
  });
};
