import { VscFileMedia } from 'react-icons/vsc';
import {FaWarehouse } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi';
import { GrUserWorker } from 'react-icons/gr';
import { BiWallet } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';

const paths = {
  home: '/',
  register: '/register',
  accountCreated: '/account-created',
  deleteAccount: '/agent-delete-account',
 deleteSuccess: '/acount-deleted'
}


export const pageTitles = {
  dashboard: 'Dashboard',

}

export const NavNames = {
  dashboard: 'Dashboard',

}

export const pathObject = [
  { name: NavNames.dashboard, path: paths.home, icon: <FaWarehouse /> },
]




export default paths