import { useEffect, useMemo } from "react";
import {
  Grid,
  GridItem,
  Center,
  Heading,
  VStack,
  Image,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import generateInputs from "../../components/DynamicForm";

import { useLogin, useDeleteAccount } from "./queryHooks";
import GenericModal from "../../components/Modal";

type Props = {};

const schema = yup
  .object()
  .shape({
    password: yup.string().required(),
    username: yup.string().required(),
  })
  .required();

type FormValues = Record<string, string>;

const SignIn = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate, isLoading, status, data } = useLogin();
  const { mutate: deleteAccountMutation, isLoading: deleting} =
    useDeleteAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  const inputObj: InputObj[] = useMemo(
    () => [
      {
        name: "username",
        label: "Email",
        type: "email",
        register: register("username", {
          required: "Email is required",
        }),
        isInvalid: !!errors.email,
        error: errors?.email,
      },
      {
        name: "password",
        label: "Password",
        type: "text",
        register: register("password", {
          required: "Password is required",
        }),
        isInvalid: !!errors.email,
        error: errors?.email,
      },
    ],
    [errors.password, errors.email, register]
  );

  const onSubmit = (data: FormValues) => {
    mutate({ data });
  };

  const handleDeleteAccount = () => {
    console.log(data?.data.object.id);
    deleteAccountMutation({agentId: data?.data.object.id})
  };

  useEffect(() => {
    if (status === "success") {
      onOpen();
    }
  }, [status]);

  return (
    <Grid
      minH="100vh"
      templateRows="repeat(10, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem
        rowSpan={10}
        colSpan={{ base: 0, lg: 5 }}
        backgroundImage="url(/images/afrimashside2.png)"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        display={{ base: "none", lg: "block" }}
      ></GridItem>
      <GridItem rowSpan={10} colSpan={{ base: 12, lg: 7 }} p={2}>
        <Center h="95%">
          <VStack width={{ base: "100%", lg: "60%" }} spacing={6}>
            <Image
              src="/images/afrimash.svg"
              boxSize={{ base: "100px", md: "10vw" }}
            />
            <Heading as="h1" size="xl" textAlign="center">
              Delete Agent Account
            </Heading>
            <Text fontSize="xl">
              Your account will be deleted and you will no longer have access to
              your data. Please understand that this action is irriversible.
            </Text>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              {inputObj.map((input) => generateInputs(input))}
              <Button
                mt="4"
                variant="destructive"
                type="submit"
                isLoading={isLoading}
              >
                Delete Account
              </Button>
            </form>
          </VStack>
        </Center>
        <GenericModal
          onOk={handleDeleteAccount}
          isOpen={isOpen}
          onClose={onClose}
          title="Delete Account"
          isLoading={deleting}
          children={
            <Text>
              Are you sure you want to delete your account on Afrimash Agent App
            </Text>
          }
        />
      </GridItem>
    </Grid>
  );
};

export default SignIn;
