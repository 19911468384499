import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type Props = {
  title: string;
  isOpen: boolean;
  onClose(): void;
  children: React.ReactNode;
  onOk(): void;
  isLoading: boolean;
};

function GenericModal({
  title,
  isOpen,
  onClose,
  children,
  onOk,
  isLoading,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            onClick={onOk}
            width="object-fit"
            variant="destructive"
          >
            Delete Account
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GenericModal;
