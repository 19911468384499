import endpoints from "../../utils/endpoints";
import { publicAxios as axios, setupAuthAxios } from "../../configs/axios";
import { AxiosInstance } from "axios";

class Services {
  constructor() {}

  login(payload: Params) {
    return axios({
      method: "POST",
      url: endpoints.login,
      data: payload.data,
    });
  }

  deleteAccount({ agentId }: { agentId: number }) {
    const authAxios = setupAuthAxios(
      process.env.REACT_APP_BASE_URL,
      localStorage.authToken ?? ""
    );
    return authAxios({
      method: "DELETE",
      url: endpoints.deleteAgent(agentId),
    });
  }
}

const services = new Services();

export default services;
