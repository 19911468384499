import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { Routes, Route } from "react-router-dom";

import { setupPublicAxios } from "./configs/axios";

import RegisterAgent from "./views/Register";
import SuccessPage from "./views/SuccessPage";
import DeleteAccount from "./views/DeleteAccount";
import DeleteSuccess from "./views/SuccessPage/DeleteSuccess";

import paths from "./utils/paths";

setupPublicAxios(process.env.REACT_APP_BASE_URL);

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path={paths.home} element={<DeleteAccount />} />
        <Route path={paths.register} element={<RegisterAgent />} />
        <Route path={paths.accountCreated} element={<SuccessPage />} />
        <Route path={paths.deleteSuccess} element={<DeleteSuccess />} />
      </Routes>
    </ChakraProvider>
  );
};
